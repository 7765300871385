<template>
  <div>
    <Breadcrumb :breadcrumbList="breadcrumbList" />
    <!-- 顶部搜索 -->
    <div class="header">
      <div class="header_title">{{ nav }}</div>
      <div class="header_product">FIND YOUR PRODUCT</div>
      <div class="header_input">
        <input
          type="text"
          v-model="productName"
          placeholder="Find Your Product"
        />
        <!-- <el-select
          v-model="productName"
          filterable
          placeholder="Find Your Product"
          class="select select1 select2"
          @change="checkProductName"
        >
          <el-option
            v-for="item in nameList"
            :key="item.value"
            :label="item.value"
            :value="item.id"
          >
          </el-option>
        </el-select> -->
        <img src="@/assets/index/sousuo.png" alt="" @click="search" />
      </div>
    </div>
    <!-- 零售产品列表 -->
    <div class="retaitList" v-show="isP">
      <div class="retaitList_title">
        <p v-html="typeDescription"></p>
      </div>
      <div class="retaitList_main">
        <div
          class="retaitList_Box"
          v-for="item in showProductList"
          :key="item.id"
          @click="retaltInfo(item.id)"
        >
          <img :src="item.url" alt="" />
          <span></span>
          <div>
            <div>{{ item.name }}</div>
            <div>{{ item.description }}</div>
          </div>
        </div>
      </div>
      <div class="retaitList_arrows">
        <img
          src="@/assets/index/left.png"
          alt=""
          class="left"
          @click="changePage(-1)"
        />
        <img
          src="@/assets/index/rigth.png"
          alt=""
          class="right"
          @click="changePage(1)"
        />
      </div>
      <div class="retaitList_circle">
        <span
          v-for="(item, index) in page"
          :key="index"
          :class="showPage == index + 1 ? 'checkSpan' : ''"
          @click="changePage2(index + 1)"
        ></span>
      </div>
    </div>
    <!-- 成功案例 -->
    <div class="stories">
      <div class="stories_title">SUCCESS STORIES</div>
      <div class="stories_main">
        <div
          class="stories_box"
          v-for="(item, index) in storiesList"
          :key="index"
          @click="storiesInfo(item.id)"
        >
          <img :src="item.mainImgUrl" alt="" />
          <div>
            <div>{{ item.name }}</div>
            <div>{{ item.titleDescription }}</div>
          </div>
        </div>
      </div>
      <div class="stories_showAll">
        <div @click="successStories()">VIEW ALL</div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/breadcrumb/breadcrumb";
import api from "@/utils/api";
export default {
  mounted() {
    // console.log(sessionStorage.getItem("proTypeListName"),sessionStorage.getItem("proTwoTypeListName"))
    //面包屑导航
    if (sessionStorage.getItem("proTwoTypeListName")) {
      this.nav = sessionStorage.getItem("proTwoTypeListName");
      this.breadcrumbList = [
        { path: "/", title: "HOME" },
        { path: "/", title: "PRODUCTS" },
        {
          path: "/retalt?t=0&id=" + sessionStorage.getItem("productSearch"),
          title: sessionStorage.getItem("proTypeListName"),
        },
        { path: "", title: sessionStorage.getItem("proTwoTypeListName") },
      ];
    } else {
      this.nav = sessionStorage.getItem("proTypeListName");
      this.breadcrumbList = [
        { path: "/", title: "HOME" },
        { path: "/", title: "PRODUCTS" },
        { path: "/", title: sessionStorage.getItem("proTypeListName") }
      ];
    }
    this.successStoriesRecommendList();
    if (this.$route.query.id) {
      this.productSearch(this.$route.query.id);
      this.productNameList(this.$route.query.id);
      sessionStorage.setItem("productSearch", this.$route.query.id);
    } else {
      this.productSearch(sessionStorage.getItem("productSearch"));
      this.productNameList(sessionStorage.getItem("productSearch"));
    }
  },
  data() {
    return {
      breadcrumbList: [],
      storiesList: [],
      productList: [], //商品列表
      showProductList: [], //显示的商品列表
      nameList: [], //产品名称列表
      productName: "", //输入框中输入的名称
      page: 3, //总页数
      showPage: 1, //当前页
      total: 14,
      typeDescription: "", //
      isP: 0,
      nav: "RETAIL",
    };
  },
  components: {
    Breadcrumb,
  },
  watch: {
    $route(to) {
      // console.log(to.query.t != 0);
          // console.log(sessionStorage.getItem("proTypeListName"),sessionStorage.getItem("proTwoTypeListName"))
      if (sessionStorage.getItem("proTwoTypeListName")  && to.query.t != 0) {
        this.nav = sessionStorage.getItem("proTwoTypeListName");
        this.breadcrumbList = [
          { path: "/", title: "HOME" },
          { path: "/", title: "PRODUCTS" },
          {
           path: "/retalt?t=0&id=" + sessionStorage.getItem("productSearch"),
            title: sessionStorage.getItem("proTypeListName"),
          },
          { path: "", title: sessionStorage.getItem("proTwoTypeListName") },
        ];
      } else {
        this.nav = sessionStorage.getItem("proTypeListName");
        this.breadcrumbList = [
          { path: "/", title: "HOME" },
          { path: "/", title: "PRODUCTS" },
          { path: "/", title: sessionStorage.getItem("proTypeListName") }
        ];
      }
      if (to.query.id) {
        let id = to.query.id;
        this.productSearch(id);
        this.productNameList(id);
        sessionStorage.setItem("productSearch", id);
      }
    },
  },
  methods: {
    //箭头翻页
    changePage(num) {
      if (this.showPage + num < 1) {
        this.$message("没有上一页了");
      } else if (this.showPage + num > this.page) {
        this.$message("没有下一页了");
      } else {
        this.showPage = this.showPage + num;
        this.showProductList = [];
        this.showProductList = this.productList.slice(
          (this.showPage - 1) * 5,
          this.showPage * 5
        );
      }
    },
    //小圆点翻页
    changePage2(num) {
      this.showPage = num;
      //请求数据
    },
    //详情页的跳转
    retaltInfo(id) {
      document.documentElement.scrollTop = 0;
      this.$router.push({
        path: "/retaltInfo",
        query: { id },
      });
    },
    //成功案例的跳转
    successStories() {
      this.$router.push({
        name: "successStories",
      });
    },
    //成功案例详情页的跳转
    storiesInfo(id) {
      this.$router.push({
        // name: "successInfo",
        // params: { id },
		path: "/successInfo",
		query: { id },
      });
    },
    //获取成功案列列表
    successStoriesRecommendList() {
      api.successStoriesRecommendList().then((res) => {
        let data = res.data;
        if (data.code == 200) {
          this.storiesList = data.data;
        } else {
          this.$message.error(data.msg);
        }
      });
    },
    //获取二级分类
    productSearch(id) {
      api.productSearch({ oneTypeId: id }).then((res) => {
        let data = res.data;
        if (data.code == 200) {
          this.isP = 0;
          if (data.data.productList) {
            this.productList = data.data.productList;
            this.isP = 1;
          }
          this.page = Math.ceil(data.data.productList.length / 5);
          this.showProductList = this.productList.slice(0, 5);
          this.typeDescription = data.data.typeDescription;
        } else {
          this.$message.error(data.msg);
        }
      });
    },
    //获取下拉框名称
    productNameList(id) {
      api.productNameList({ oneTypeId: id }).then((res) => {
        let data = res.data;
        if (data.code == 200) {
          let that = this;
          data.data.forEach((item) => {
            that.nameList.push({ value: item.name, id: item.id });
          });
        } else {
          this.$messags.error(res.msg);
        }
      });
    },
    selectName(item) {
      this.productId = item.id;
    },
    //输入框
    // checkProductName(item) {
    //   this.productId = item;
    // },
    //搜索
    search() {
      if (this.productName) {
        api.productSearch({ keyword: this.productName }).then((res) => {
          let data = res.data;
          // console.log(data);
          if (data.code == 200) {
            this.productList = data.data.productList;
            // console.log(this.productList, data.data.productList.length);
            this.page = Math.ceil(data.data.productList.length / 5);
            this.showProductList = this.productList.slice(0, 5);
            this.typeDescription = data.data.typeDescription;
          } else {
            this.$message.error(data.msg);
          }
        });
      } else {
        this.$message.warning("请输入查询的产品！");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  height: 224px;
  background-image: url("../../assets/index/carousel.png");
  text-align: center;
  .header_title {
    padding-top: 50px;
    padding-bottom: 40px;
    height: 40px;
    font-size: 56px;
    font-family: Arial;
    font-weight: bold;
    color: #ffffff;
  }
  .header_product {
    font-size: 24px;
    height: 18px;
    font-family: Arial;
    font-weight: normal;
    color: #ffffff;
    padding-bottom: 13px;
  }
  .header_input {
    width: 350px;
    height: 43px;
    background: #ffffff;
    margin: 0 auto;
    position: relative;
    img {
      position: absolute;
      cursor: pointer;
      right: 22px;
      top: 13px;
      height: 16px;
      width: 16px;
    }
    input {
      border: 0;
      height: 40px;
      width: 310px;
      padding: 1px 20px 2px;
      font-size: 16px;
      font-family: Arial;
      font-weight: normal;
    }
    input:hover {
      border: 0;
      outline: 0;
    }
  }
}
.retaitList {
  height: 610px;
  position: relative;
  margin-bottom: 60px;
  .retaitList_title {
    margin: 0 auto;
    width: 1100px;
    // height: 88px;
    font-size: 16px;
    font-family: Arial;
    font-weight: normal;
    color: #5a5b5e;
    line-height: 24px;
    padding: 48px 0 45px;
  }
  .retaitList_main {
    display: flex;
    // justify-content: space-between;
    width: 1156px;
    margin: 0 auto;
    .retaitList_Box {
      height: 355px;
      width: 207px;
      img {
        height: 200px;
        width: 207px;
        display: block;
      }
      > div {
        background: #f0f2f7;
        opacity: 0.95;
        height: 155px;
        text-align: center;

        div:nth-of-type(1) {
          font-size: 16px;
          font-family: Arial;
          font-weight: bold;
          color: #5a5b5e;
          line-height: 60px;
          height: 60px;

          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          padding: 0 15px;
        }
        div:nth-of-type(2) {
          font-size: 16px;
          font-family: Arial;
          font-weight: 400;
          color: #5a5b5e;
          height: 72px;

          padding: 19px 15px 0;

          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
        }
      }
    }
    .retaitList_Box:hover {
      position: relative;
      > div {
        background: #004a9c;
        div {
          color: #ffffff;
        }
      }
      span {
        display: block;
        position: absolute;
        top: 0;
        width: 207px;
        height: 203px;
        background: #172f66;
        opacity: 0.2;
      }
    }
    .retaitList_Box:not(:nth-of-type(1)) {
      margin-left: 30px;
    }
  }
  .retaitList_arrows {
    position: absolute;
    cursor: pointer;
    width: 1348px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 60%);
    display: flex;
    justify-content: space-between;
    img {
      height: 46px;
      width: 25pxs;
    }
  }
  .retaitList_circle {
    height: 70px;
    text-align: center;
    span {
      cursor: pointer;
      display: inline-block;
      width: 10px;
      height: 10px;
      background: #c7c7c7;
      border-radius: 5px;
      margin: 20px 8px;
    }
    .checkSpan {
      background: #5a5b5e;
    }
  }
}
.stories {
  background: #f0f2f7;
  .stories_title {
    text-align: center;
    font-size: 36px;
    font-family: Arial;
    font-weight: normal;
    color: #004a9c;
    line-height: 24px;
    padding: 55px 0 50px;
  }
  .stories_main {
    width: 1156px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .stories_box {
      height: 540px;
      width: 563px;
      background: #ffffff;
      margin-bottom: 30px;
	  overflow: hidden;
      img {
        width: 563px;
        height: 280px;
      }
      > div {
        font-family: Arial;
        div:nth-of-type(1) {
          margin: 0 auto;
          width: 524px;
          height: 41px;
          font-size: 18px;
          font-weight: bold;
          color: #0d53a1;
          padding-top: 37px;
        }
        div:nth-of-type(2) {
          padding-top: 37px;
          margin: 0 auto;
          text-align: center;
          width: 508px;
          height: 40px;
          font-size: 14px;
          font-weight: 400;
          color: #5a5b5e;
        }
      }
    }
  }
  .stories_showAll {
    height: 95px;
    padding-top: 50px;

    div {
      cursor: pointer;
      margin: 0 auto;
      width: 263px;
      height: 45px;
      background: #ffffff;
      font-size: 18px;
      font-family: Arial;
      font-weight: bold;
      color: #0d53a1;
      text-align: center;
      line-height: 45px;
    }
  }
}
</style>


<style scoped>
div /deep/ .el-input__suffix-inner {
  display: none;
}
div /deep/ .el-select {
  font-size: 16px;
  font-family: Arial;
  font-weight: normal;
}

div /deep/ .el-input__inner {
  height: 43px;
  width: 350px;
  border-radius: 0;
}
</style>