<template>
  <div>
    <div class="breadcrumbBox">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item
          :to="{ path: item.path }"
          v-for="(item, index) in breadcrumbList"
          :key="index"
          >{{ item.title }}</el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  props: ["breadcrumbList"],
};
</script>

<style scoped>
div {
  background: #004a9c;
  height: 33px;
  width: 100%;
}
.breadcrumbBox {
  width: 1152px;
  margin: 0 auto;
  padding-left: 40PX;
}
div /deep/ .el-breadcrumb__inner a,
div /deep/ .el-breadcrumb__inner.is-link {
  width: 189px;
  height: 11px;
  font-size: 14px;
  font-family: Arial;
  font-weight: normal;
  color: #ffffff;
  line-height: 33px;
}
</style>